.navbarContainer {
  width: 100%;
  z-index: 3;
}

.navbarUpperDiv {
  display: flex;
  padding: 1% 5%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.navbarLogoDiv {
  width: 15%;
}

.navbarLogoDiv img {
  width: 100%;
}

.navbarSocialDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8%;
  width: 20%;
}

.navbarSocialDiv img {
  width: 25px;
}

.navbarLowerDiv {
  width: 100%;
  padding: 1% 5%;
  display: flex;
  align-items: center;
  gap: 3%;
  background: linear-gradient(90deg, #2d5a86 0%, #003163 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.6);
}

.navbarLowerDiv a {
  color: #f4f4f4;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
}

.navbarLowerDiv a:hover {
  transform: scale(1.05);
}

.navbarHamburger {
  display: none;
}

.mobMunuLogoSubDiv {
  width: 40%;
}

.mobMunuLogoSubDiv img {
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .navbarLogoDiv {
    width: 18%;
  }
}

@media only screen and (max-width: 900px) {
  .navbarLogoDiv {
    width: 20%;
  }

  .navbarSocialDiv {
    gap: 10%;
  }

  .navbarSocialDiv img {
    width: 20px;
  }

  .navbarLowerDiv a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .navbarLogoDiv {
    width: 25%;
  }

  .navbarLowerDiv {
    gap: 5%;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .navbarLogoDiv {
    width: 30%;
  }

  .navbarHamburger {
    display: block;
  }

  .navbarSocialDiv {
    display: none;
  }

  .navbarLowerDiv {
    display: none;
  }
}

@media only screen and (max-width: 520px) {
  .navbarLogoDiv {
    width: 40%;
  }
}

@media only screen and (max-width: 480px) {
  .mobMunuLogoSubDiv {
    width: 50%;
  }
}
