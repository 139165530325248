.telentOnBoardContainer {
  width: 100%;
  padding: 5% 10%;
  /* height: 50vh; */
  background: url(../../assets/images/mapBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
}

.darkEffect {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000099;
}

.telentOnBoardContentDiv {
  z-index: 2;
  width: 100%;
}

.telentOnBoardContentDiv p {
  text-align: right;
  margin-bottom: 2%;
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.telentOnBoardContentDiv p a {
  color: #fff;
  text-decoration: none;
}

.telentOnBoardContentDiv h2 {
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 65px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  padding: 1% 3%;
  border-left: 3px solid #ffffff;
}

.telentOnBoardCardDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
  width: 100%;
}

.telentOnBoardCardCarousel {
  width: 90%;
  display: flex !important;
  align-items: center;
  justify-content: center !important;
}

.carouselLeftButton {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: none;
}

.carouselRightButton {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: none;
}

.telentOnBoardCard {
  position: relative;
  width: 100%;
  height: 40vh;
  /* padding: 5%; */
  margin: 2% 0;
  border-radius: 20px;
  border: 1px solid #fff;
  transition: all 1s ease;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.normalDiv {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1%;
}

.normalIconDiv {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.normalIconDiv img {
  width: 50px !important;
}

.normalDiv h3 {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  margin-top: 5%;
}

.hoverDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  color: #5b97af;
  opacity: 0;
  /* Start with opacity 0 */
  transition: opacity 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 2px;
  padding: 5%;
}

.telentOnBoardCard:hover .hoverDiv {
  opacity: 1;
}

.telentOnBoardCarouselItems {
  margin: 0 5%;
}

.SliderDiv {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0 1%;
  /* overflow-x: scroll; */
}

.CarouselLeftArrow {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  left: 0 !important;
  z-index: 1000 !important;
  color: #003b7e;
  background: #f8f8f8ba !important;
  box-shadow: none !important;
  transition: 0.5s;
}

.CarouselLeftArrow:hover {
  background: #003b7eba !important;
  color: #f8f8f8 !important;
}

.CarouselRightArrow {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  right: 0 !important;
  color: #003b7e;
  background: #f8f8f8aa !important;
  box-shadow: none !important;
  transition: 0.5s;
}

.CarouselRightArrow:hover {
  background: #003b7eba !important;
  color: #f8f8f8 !important;
}

@media only screen and (max-width: 1200px) {
  .telentOnBoardContentDiv h2 {
    font-size: 56px;
  }

  .telentOnBoardCard {
    height: 35vh;
  }

  .normalDiv h3 {
    font-size: 22px;
  }

  .hoverDiv {
    line-height: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .telentOnBoardContentDiv h2 {
    font-size: 42px;
  }

  .telentOnBoardCard {
    height: 30vh;
  }

  .normalDiv h3 {
    font-size: 22px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 900px) {
  .telentOnBoardContentDiv h2 {
    font-size: 36px;
  }

  .normalIconDiv {
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }

  .normalDiv h3 {
    font-size: 18px;
  }

  .hoverDiv {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .telentOnBoardCardDiv {
    flex-direction: column;
    margin-top: 5%;
    gap: 30px;
  }

  .telentOnBoardCard {
    height: 35vh;
  }

  .normalIconDiv {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }

  .normalDiv h3 {
    font-size: 24px;
  }
}

/* @media only screen and (max-width: 600px) {
  .telentOnBoardCard {
    width: 80%;
  }
} */

@media only screen and (max-width: 520px) {
  .telentOnBoardContentDiv h2 {
    font-size: 28px;
    margin: 5% 0;
  }

  .telentOnBoardCardDiv {
    margin-top: 10%;
  }

  /* .telentOnBoardCard {
    width: 90%;
  } */

  .normalDiv {
    padding: 3%;
  }

  .normalDiv h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .telentOnBoardCard {
    /* width: 100%; */
    height: 30vh;
  }
}
