.faqsContainer {
  width: 100%;
  padding: 5% 8%;
}

.faqsHeadingDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1%;
  margin-bottom: 5%;
}

/* .faqsHeadingDiv img {
    width: 10%;

} */

.faqsHeadingDiv h2 {
  color: #004896;
  text-align: center;
  font-family: Poppins;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.faqsHeadingDiv h2 span {
  color: #b9b9b9;
}

.faqsListDiv {
  width: 100%;
}

.faqsListbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #004896;
}

.faqsListTitleDiv {
  width: 95%;
  display: flex;
  align-items: center;
  gap: 2%;
  padding: 1% 2%;
}

.faqsListTitleDiv h3 {
  width: 100%;
  color: #004896;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.faqsListCollapseButton {
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.faqsListCollapseData {
  width: 100%;
  padding: 1% 2%;
}

.faqsListCollapseData ul {
  width: 100%;
  color: #5c5959;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.faqsListCollapseData ul li {
  margin-left: 5%;
}

.faqsListCollapseData p {
  width: 100%;
  color: #5c5959;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media only screen and (max-width: 1200px) {
  .faqsHeadingDiv h2 {
    font-size: 32px;
  }

  .faqsListTitleDiv h3 {
    font-size: 24px;
  }

  .faqsListCollapseData ul {
    font-size: 22px;
  }

  .faqsListCollapseData p {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1024px) {
  .faqsHeadingDiv h2 {
    font-size: 30px;
  }

  .faqsListTitleDiv h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 900px) {
  .faqsHeadingDiv h2 {
    font-size: 28px;
  }

  .faqsListTitleDiv h3 {
    font-size: 20px;
  }

  .faqsListCollapseData ul {
    font-size: 20px;
  }

  .faqsListCollapseData p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .faqsHeadingDiv h2 {
    font-size: 24px;
  }

  .faqsListTitleDiv h3 {
    font-size: 18px;
  }

  .faqsListCollapseData ul {
    font-size: 18px;
  }

  .faqsListCollapseData p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .faqsHeadingDiv h2 {
    font-size: 22px;
  }

  .faqsListTitleDiv h3 {
    font-size: 16px;
  }

  .faqsListCollapseData ul {
    font-size: 16px;
  }

  .faqsListCollapseData p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 520px) {
  .faqsHeadingDiv h2 {
    font-size: 20px;
  }

  .faqsListTitleDiv h3 {
    font-size: 14px;
  }

  .faqsListCollapseData ul {
    font-size: 14px;
  }

  .faqsListCollapseData p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .faqsHeadingDiv h2 {
    font-size: 18px;
  }

  .faqsListTitleDiv h3 {
    font-size: 13px;
  }

  .faqsListCollapseData ul {
    font-size: 13px;
  }

  .faqsListCollapseData p {
    font-size: 13px;
  }
}
