.heroContainer {
  position: relative;
  width: 100%;
  height: 75vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: black;
}

.videoBackground {
  position: absolute;
  top: 0;
  right: -20%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding: 0 8%;
  color: white;
  flex-direction: column;
}

.content h2 {
  color: #fff;
  font-family: Poppins;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 12px;
}

.content h1 {
  color: #5b97af;
  font-family: Poppins;
  font-size: 92px;
  font-style: normal;
  font-weight: 700;
  line-height: 73px;
  letter-spacing: 14px;
}

.content button {
  padding: 1% 3%;
  margin-top: 3%;
  border-radius: 15px;
  border: none;
  background: #5b97af;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .videoBackground {
    right: 0;
  }

  .content {
    align-items: center;
    padding: 0;
  }

  .content h2 {
    font-size: 32px;
  }

  .content h1 {
    font-size: 75px;
  }

  .content button {
    padding: 2% 0;
    width: 40%;
    font-size: 20px;
  }
}

@media only screen and (max-width: 520px) {
  .content h2 {
    font-size: 28px;
  }

  .content h1 {
    font-size: 50px;
  }

  .content button {
    width: 60%;
  }
}

@media only screen and (max-width: 480px) {
  .content h2 {
    font-size: 22px;
    line-height: 40px;
    letter-spacing: 10px;
  }

  .content h1 {
    font-size: 40px;
    line-height: 60px;
    letter-spacing: 12px;
  }

  .content button {
    width: 80%;
    padding: 3% 0;
    font-size: 18px;
  }
}
