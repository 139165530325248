.videoSectionContainer {
  width: 100%;
  padding: 5% 9%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10%;
}

.videoSectionContainer video {
  width: 50%;
  border-radius: 8px;
}

.videoSectionTextDiv {
  width: 40%;
}

.videoSectionTextDiv h2 {
  color: #232323;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px;
  /* 120% */
}

.videoSectionTextDiv p {
  color: #1e1e1e;
  font-family: Poppins;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 139.13% */
}

@media only screen and (max-width: 1200px) {
  .videoSectionContainer {
    gap: 5%;
  }

  .videoSectionTextDiv {
    width: 45%;
  }

  .videoSectionTextDiv h2 {
    font-size: 36px;
    line-height: 42px;
  }

  .videoSectionTextDiv p {
    margin-top: 5%;
    font-size: 20px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .videoSectionTextDiv h2 {
    font-size: 32px;
  }

  .videoSectionTextDiv p {
    margin-top: 5%;
    font-size: 18px;
  }
}

@media only screen and (max-width: 900px) {
  .videoSectionContainer {
    gap: 20px;
    flex-direction: column;
  }

  .videoSectionContainer video {
    width: 100%;
  }

  .videoSectionTextDiv {
    width: 70%;
    text-align: center;
    margin-top: 5%;
  }

  .videoSectionTextDiv p {
    margin-top: 2%;
  }
}

@media only screen and (max-width: 768px) {
  .videoSectionTextDiv {
    width: 100%;
    text-align: left;
    margin-top: 5%;
  }
}

@media only screen and (max-width: 600px) {
  .videoSectionContainer {
    padding: 5%;
  }

  .videoSectionTextDiv h2 {
    font-size: 28px;
  }

  .videoSectionTextDiv p {
    font-size: 16px;
  }
}
