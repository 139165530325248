.serviceContactContainer {
  padding: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.serviceContactHeadingDiv {
  width: 100%;
  text-align: center;
}

.serviceContactHeadingDiv h3 {
  width: 100%;
  color: #000;
  font-family: Poppins;
  font-size: 36px;
  font-weight: 500;
}

.serviceContactHeadingDiv p {
  width: 100%;
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 400;
}

.serviceContactFormContainer {
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 5%;
  margin-top: 5%;
}

.serviceContactFormGif {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.serviceContactFormGif img {
  width: 90%;
}

.serviceContactFormDiv {
  width: 50%;
}

.serviceContactForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.serviceContactFieldDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.serviceContactFieldDiv p {
  width: 100%;
  color: #004896;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

.serviceContactFieldDiv input {
  width: 100%;
  border-radius: 7px;
  border: 1.5px solid #004896;
  background: rgba(208, 217, 227, 0.5);
  padding: 2%;
}

.serviceContactFieldDiv textarea {
  width: 100%;
  border-radius: 7px;
  border: 1.5px solid #004896;
  background: rgba(208, 217, 227, 0.5);
  padding: 2%;
  resize: none;
  height: 240px;
}

.serviceContactButtonDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 2%;
}

.serviceContactButtonDiv button {
  width: 30%;
  padding: 1% 0;
  border-radius: 105px;
  border: 1px solid #272d40;
  background: #5b97af;
  color: #fff;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 500;
}

@keyframes upAndDown {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-50px);
  }
}

.robotAnimation {
  animation: upAndDown 2s infinite;
  /* Adjust the duration (2s) as needed */
}

@media only screen and (max-width: 1200px) {
  .serviceContactFormGif {
    width: 35%;
  }

  .serviceContactFormGif img {
    width: 100%;
  }

  .serviceContactFormDiv {
    width: 60%;
  }

  .serviceContactFormContainer {
    align-items: center;
  }
}

@media only screen and (max-width: 1024px) {
  .serviceContactHeadingDiv h3 {
    font-size: 32px;
  }

  .serviceContactHeadingDiv p {
    font-size: 18px;
  }

  .serviceContactFormContainer {
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .serviceContactFormGif {
    width: 100%;
  }

  .serviceContactFormGif img {
    width: 40%;
  }

  .serviceContactFormDiv {
    width: 70%;
  }
}

@media only screen and (max-width: 900px) {
  .serviceContactFormGif img {
    width: 50%;
  }

  .serviceContactFormDiv {
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .serviceContactHeadingDiv h3 {
    font-size: 28px;
  }

  .serviceContactHeadingDiv p {
    font-size: 16px;
  }

  .serviceContactFormDiv {
    width: 90%;
  }

  .serviceContactButtonDiv button {
    font-size: 22px;
  }
}

@media only screen and (max-width: 600px) {
  .serviceContactHeadingDiv h3 {
    font-size: 24px;
  }

  .serviceContactFormGif {
    margin: 5% 0;
  }

  .serviceContactFormGif img {
    width: 60%;
  }

  .serviceContactFormDiv {
    width: 100%;
  }

  .serviceContactButtonDiv button {
    width: 50%;
  }
}

@media only screen and (max-width: 480px) {
  .serviceContactHeadingDiv h3 {
    font-size: 20px;
  }

  .serviceContactHeadingDiv p {
    font-size: 14px;
  }
}
