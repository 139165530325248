.aboutUsSectionContainer {
  width: 100%;
  padding: 3% 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aboutUsSectionHeadingDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.aboutUsSectionHeadingDiv h2 {
  color: #004896;
  font-family: Epilogue;
  font-size: 42px;
  font-style: normal;
  font-weight: 800;
  line-height: 80px;
}

.aboutUsSectionHeadingDiv h2 span {
  color: #323232;
}

.aboutUsSectionHeadingDiv p {
  width: 50%;
  color: #252525;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.aboutUsProfileContainer {
  width: 100%;
  margin-top: 5%;
}

.aboutUsProfileHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1%;
}

/* .aboutUsProfileHeading img {
    width: 8%;
} */

.aboutUsProfileHeading h3 {
  color: #004896;
  font-family: Poppins;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 3.8px;
}

.aboutUsProfileHeading h3 span {
  color: #b9b9b9;
}

.aboutUsProfileCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
  margin: 2% 0;
}

.aboutUsProfileCard {
  width: 24%;
}

.aboutUsProfileCard img {
  width: 100%;
}

.aboutUsProfileCardInfo {
  padding-left: 30%;
  margin-top: 3%;
}

.aboutUsProfileCardInfo h3 {
  color: #10539d;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.2px;
  white-space: nowrap;
}

.aboutUsProfileCardInfo h4 {
  color: #818181;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.6px;
  white-space: nowrap;
}

.aboutUsProfileCardInfo img {
  width: 25px;
}

.sectionDivider {
  height: 1px;
  background-color: #004896;
  width: 90%;
  margin: 2% 0;
}

@media only screen and (max-width: 1500px) {
  .aboutUsSectionHeadingDiv p {
    width: 70%;
  }
}

@media only screen and (max-width: 1200px) {
  .aboutUsProfileCardContainer {
    margin: 5% 0;
  }

  .aboutUsProfileCard {
    width: 30%;
  }
}

@media only screen and (max-width: 1024px) {
  .aboutUsSectionHeadingDiv h2 {
    font-size: 38px;
    line-height: 70px;
  }

  .aboutUsSectionHeadingDiv p {
    width: 90%;
    font-size: 16px;
  }

  /* .aboutUsProfileHeading img {
        width: 10%;
    } */
}

@media only screen and (max-width: 900px) {
  .aboutUsProfileCard {
    width: 35%;
  }

  .aboutUsProfileCardInfo h3 {
    font-size: 18px;
  }

  .aboutUsProfileCardInfo h4 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .aboutUsSectionHeadingDiv p {
    width: 95%;
    font-size: 14px;
  }

  /* .aboutUsProfileHeading img {
        width: 13%;
    } */

  .aboutUsProfileCard {
    width: 40%;
  }
}

@media only screen and (max-width: 600px) {
  .aboutUsSectionContainer {
    padding: 5%;
  }

  .aboutUsProfileContainer {
    margin: 10% 0;
  }

  /* .aboutUsProfileHeading img {
        width: 17%;
    } */

  .aboutUsProfileCardContainer {
    flex-direction: column;
  }

  .aboutUsProfileCard {
    width: 60%;
    margin: 5% 0;
  }
}

@media only screen and (max-width: 480px) {
  .aboutUsSectionHeadingDiv p {
    width: 100%;
  }

  /* .aboutUsProfileHeading img {
        width: 20%;
    } */

  .aboutUsProfileHeading {
    gap: 5%;
  }

  .aboutUsProfileHeading h3 {
    font-size: 24px;
  }

  .aboutUsProfileCard {
    width: 90%;
  }
}
