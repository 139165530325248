.WeBelieveContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.WeBelieveHeadingDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1%;
}

/* .WeBelieveHeadingDiv img {
    width: 7%;

} */

.WeBelieveHeadingDiv h3 {
  color: #b9b9b9;
  font-family: Poppins;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 3.8px;
}

.WeBelieveHeadingDiv h3 span {
  color: #004896;
}

.WeBelieveHeadingPara {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.3px;
  margin: 2% 0;
  text-align: center;
  width: 70%;
}

.WeBelieveMissionVisionDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
  margin: 2% 0;
}

.WeBelieveMissionDiv {
  width: 35%;
  height: 20vh;
  padding: 2%;
  border-radius: 28px;
  overflow: hidden;
  background: #d9d9d9;
  text-align: center;
  background: url(../../assets/images/footerBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.WeBelieveMissionDiv h3 {
  color: #5cfdf7;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.2px;
  position: relative;
  z-index: 1;
}

.WeBelieveMissionDiv p {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
  margin-top: 3%;
  position: relative;
  z-index: 1;
}

.WeBelieveStatsDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3%;
  margin: 2% 0 4% 0;
}

.WeBelieveStats {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.WeBelieveStatsIconDiv {
  width: 170px;
  height: 170px;
  border-radius: 85px;
  background-color: #1a2653;
  display: flex;
  align-items: center;
  justify-content: center;
}

.WeBelieveStatsIconDiv img {
  width: 80%;
}

.WeBelieveStats h3 {
  color: #353535;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.WeBelieveStats h2 {
  color: #1a2653;
  font-family: Poppins;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  white-space: nowrap;
}

.verticalDivider {
  width: 1px;
  background: #1a2653;
  height: 250px;
}

@media only screen and (max-width: 1500px) {
  .WeBelieveStats h3 {
    font-size: 38px;
  }

  .WeBelieveStats h2 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 1200px) {
  /* .WeBelieveHeadingDiv img {
        width: 10%;

    } */

  .WeBelieveHeadingPara {
    width: 80%;
  }

  .WeBelieveMissionDiv {
    width: 40%;
    height: 17vh;
  }

  .WeBelieveMissionDiv h3 {
    font-size: 20px;
  }

  .WeBelieveMissionDiv p {
    font-size: 14px;
  }

  .WeBelieveStatsIconDiv {
    width: 150px;
    height: 150px;
    border-radius: 75px;
  }

  .WeBelieveStats h3 {
    font-size: 32px;
  }

  .WeBelieveStats h2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1024px) {
  .WeBelieveMissionVisionDiv {
    flex-direction: column;
  }

  .WeBelieveMissionDiv {
    width: 50%;
    height: 100%;
    margin: 2% 0;
    padding: 5%;
  }

  .WeBelieveStatsIconDiv {
    width: 120px;
    height: 120px;
    border-radius: 60px;
  }

  .WeBelieveStats h3 {
    font-size: 30px;
  }

  .WeBelieveStats h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 900px) {
  .WeBelieveMissionDiv {
    width: 60%;
  }

  .WeBelieveStatsDiv {
    margin: 6% 0;
    flex-direction: column;
  }

  .verticalDivider {
    width: 50%;
    height: 1px;
    margin: 8% 0;
  }

  .WeBelieveStatsIconDiv {
    width: 200px;
    height: 200px;
    border-radius: 100px;
  }

  .WeBelieveStats h3 {
    font-size: 38px;
  }

  .WeBelieveStats h2 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 768px) {
  /* .WeBelieveHeadingDiv img {
        width: 13%;

    } */

  .WeBelieveHeadingDiv h3 {
    font-size: 36px;
  }

  .WeBelieveMissionDiv {
    width: 70%;
    margin: 3% 0;
  }

  .verticalDivider {
    width: 70%;
  }
}

@media only screen and (max-width: 600px) {
  /* .WeBelieveHeadingDiv img {
        width: 17%;

    } */

  .WeBelieveHeadingDiv h3 {
    font-size: 32px;
  }

  .WeBelieveHeadingPara {
    width: 90%;
  }

  .WeBelieveMissionDiv {
    width: 80%;
  }

  .WeBelieveStatsDiv {
    margin: 10% 0;
  }

  .WeBelieveStatsIconDiv {
    width: 150px;
    height: 150px;
    border-radius: 75px;
  }

  .WeBelieveStats h3 {
    font-size: 32px;
  }

  .WeBelieveStats h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 480px) {
  /* .WeBelieveHeadingDiv img {
        width: 17%;
    } */

  .WeBelieveHeadingDiv h3 {
    font-size: 24px;
  }

  .WeBelieveHeadingPara {
    font-size: 12px;
    margin: 4% 0;
  }

  .WeBelieveMissionDiv {
    width: 90%;
  }

  .WeBelieveMissionDiv h3 {
    font-size: 18px;
  }

  .WeBelieveMissionDiv p {
    font-size: 12px;
  }
}
