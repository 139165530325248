.testimonialContainer {
  width: 100%;
  padding: 2% 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonialHeadingDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 15px;
}

.testimonialHeadingDiv p {
  color: #1e1e1e;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.testimonialHeadingDiv h2 {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  line-height: 64px;
  letter-spacing: -1px;
}

.testimonialReviewDiv {
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.testimonialReviewBox {
  width: 40%;
  border-radius: 6px 0px 0px 0px;
  border: 1px solid #004896;
  box-shadow: 0px 32px 64px 0px rgba(57, 20, 0, 0.04);
  padding: 2%;
}

.testimonialReviewBox p {
  color: rgba(38, 38, 38, 0.64);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin: 2% 0;
}

.testimonialAvatarDiv {
  display: flex;
  gap: 3%;
}

.testimonialAvatarDiv h3 {
  color: #004896;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
