.whoAreWeContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8% 10% 5% 10%;
}

.whoAreWeContentDiv {
  width: 50%;
}

.whoAreWeContentHeadingDiv {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5%;
}

/* .whoAreWeContentHeadingDiv img {
    width: 20%;

} */

.whoAreWeContentHeadingDiv h2 {
  color: #000;
  font-family: Poppins;
  font-size: 45px;
  font-weight: 400;
}

.whoAreWeContentDespDiv p {
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 400;
  margin: 8% 0;
}

.whoAreWeContentButtonDiv {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5%;
}

.whoAreWeContentButtonDiv h3 {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
}

.whoAreWeButtonIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #004896;
}

.whoAreWeImageDiv {
  width: 40%;
}

.whoAreWeImageDiv img {
  width: 100%;
}

.whoAreWeImageMobDiv {
  display: none;
}

.whoAreWeImageMobDiv img {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .whoAreWeContentHeadingDiv h2 {
    font-size: 38px;
  }

  .whoAreWeContentDespDiv p {
    font-size: 18px;
  }

  .whoAreWeContentButtonDiv h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .whoAreWeContentHeadingDiv h2 {
    font-size: 32px;
  }

  .whoAreWeContentDespDiv p {
    font-size: 16px;
  }

  .whoAreWeContentButtonDiv h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 900px) {
  .whoAreWeContainer {
    padding: 8% 5% 5% 5%;
  }

  .whoAreWeContentDiv {
    width: 55%;
  }

  .whoAreWeContentHeadingDiv h2 {
    font-size: 30px;
  }

  .whoAreWeButtonIcon {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .whoAreWeContainer {
    flex-direction: column;
    gap: 30px;
  }

  .whoAreWeContentDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .whoAreWeContentHeadingDiv {
    justify-content: center;
  }

  /* .whoAreWeContentHeadingDiv img {
        width: 15%;

    } */

  .whoAreWeContentButtonDiv {
    justify-content: center;
  }

  .whoAreWeContentDespDiv p {
    margin: 5% 0;
  }

  .whoAreWeImageDiv {
    display: none;
    width: 100%;
  }

  .whoAreWeImageMobDiv {
    display: block;
  }
}

@media only screen and (max-width: 520px) {
  .whoAreWeContentDespDiv p {
    text-align: center;
  }
}
