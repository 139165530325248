/* Importing all CSS files */
@import url('./common/Navbar.css');
@import url('./home/Hero.css');
@import url('./home/WhoAreWe.css');
@import url('./home/TalentOnBoard.css');
@import url('./home/VideoSection.css');
@import url('./home/HelpSection.css');
@import url('./home/Faqs.css');
@import url('./home/Testimonial.css');
@import url('./common/Footer.css');
@import url('./aboutUs/AboutUsSection.css');
@import url('./aboutUs/WeBelieve.css');
@import url('./services/WeOffer.css');
@import url('./services/ServiceContact.css');
@import url('./contactus/ContactForm.css');
@import url('./common/ReviewCard.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&family=Roboto:wght@400;500;700&display=swap');

/* Defaults */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Primary Variables */
:root {
  --primary: 'green';
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
