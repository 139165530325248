.footerContainer {
  width: 100%;
  padding: 2% 5% 0.5% 5%;
  background: url(../../assets/images/footerBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.footerSubContainer {
  position: relative;
  width: 100%;
  z-index: 2;
}

.footerUpperDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 13%;
}

.footerLogoDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.footerSocialIconsDiv {
  width: 100%;
  display: flex;
  gap: 10%;
  align-items: center;
  margin-top: 10%;
}

.footerSocialIconsDiv img {
  width: 25px;
}

.footerRoutesDiv {
  display: flex;
  flex-direction: column;
}

.footerRoutesDiv h3 {
  color: #5cfdf7;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10%;
}

.footerRoutesDiv a {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 4% 0;
  text-decoration: none;
}

.footerInfoDiv {
  display: flex;
  flex-direction: column;
}

.footerInfoDiv h3 {
  color: #5cfdf7;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10%;
}

.footerInfoDiv a {
  text-decoration: none;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 4% 0;
  display: flex;
  align-items: center;
  gap: 3%;
  white-space: nowrap;
}

.footerDivider {
  height: 2px;
  background-color: aliceblue;
  width: 100%;
  margin-top: 2%;
}

.footerCopyrightDiv {
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.5%;
}

.footerSocialMobIconsDiv {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .footerCopyrightDiv {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1024px) {
  .footerRoutesDiv h3 {
    font-size: 22px;
  }

  .footerInfoDiv h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 900px) {
  .footerUpperDiv {
    flex-wrap: wrap;
  }

  .footerLogoDiv {
    width: 100%;
    align-items: center;
    margin-bottom: 2%;
  }

  .footerSocialIconsDiv {
    display: none;
  }

  .footerRoutesDiv {
    width: 40%;
    align-items: center;
  }

  .footerInfoDiv {
    width: 40%;
    align-items: center;
  }

  .footerRoutesDiv h3 {
    margin-bottom: 5%;
  }

  .footerRoutesDiv a {
    font-size: 16px;
    margin: 7px 0;
  }

  .footerInfoDiv h3 {
    margin-bottom: 5%;
  }

  .footerInfoDiv p {
    margin: 7px 0;
  }
}

@media only screen and (max-width: 600px) {
  .footerLogoDiv {
    margin-bottom: 5%;
  }

  .footerRoutesDiv {
    width: 100%;
    margin-bottom: 20px;
  }

  .footerInfoDiv {
    width: 100%;
    margin-bottom: 20px;
  }

  .footerRoutesDiv h3 {
    margin-bottom: 3%;
  }

  .footerRoutesDiv a {
    margin: 10px 0;
  }

  .footerInfoDiv h3 {
    margin-bottom: 3%;
  }

  .footerInfoDiv p {
    margin: 10px 0;
  }

  .footerSocialMobIconsDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8%;
    margin: 5% 0;
  }

  .footerSocialMobIconsDiv img {
    width: 25px;
  }
}
