.weOfferContainer {
  width: 100%;
  padding: 5%;
}

.weOfferHeadingDiv {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1% 0;
}

.weOfferHeadingDiv h3 {
  color: #004896;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 800;
}

.weOfferHeadingDiv p {
  color: #252525;
  width: 60%;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.weOfferCardDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin: 4% 0 2% 0;
}

.weOfferCard {
  padding: 4% 0;
  width: 95%;
  border-radius: 26px;
  background: linear-gradient(90deg, rgba(45, 90, 134, 0.2) 0%, rgba(0, 49, 99, 0.2) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}

.weOfferCardImageDiv {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.weOfferCardImageDiv img {
  width: 80%;
}

.weOfferCardTextDiv {
  width: 50%;
}

.weOfferCardTextDiv h3 {
  color: #000;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  margin-left: 2%;
}

.weOfferCardBulletDiv {
  display: flex;
  align-items: center;
  gap: 3%;
  margin: 5% 0;
}

.weOfferCardBulletDiv a {
  text-decoration: none;
}

.weOfferCardBulletDiv img {
  width: 50px;
}

.weOfferCardBulletDiv p {
  color: #252525;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 300;
  display: flex;
  align-items: center;
}

@keyframes rotateClockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateCounterclockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.animatedHexa {
  position: relative;
  width: 130px;
  /* Adjust the size as needed */
  height: 130px;
  /* Adjust the size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.img1,
.img2 {
  position: absolute;
  transform: translate(-50%, -50%);
}

.img1 {
  width: 120px;
  /* Adjust the size as needed */
  animation: rotateClockwise 10s linear infinite;
}

.img2 {
  width: 110px;
  /* Adjust the size as needed */
  animation: rotateCounterclockwise 10s linear infinite;
}

@media only screen and (max-width: 1500px) {
  .weOfferHeadingDiv h3 {
    font-size: 38px;
  }

  .weOfferHeadingDiv p {
    width: 80%;
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .weOfferCardTextDiv h3 {
    font-size: 32px;
  }

  .weOfferCardBulletDiv img {
    width: 50px;
  }

  .weOfferCardBulletDiv p {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1024px) {
  .weOfferHeadingDiv {
    margin: 2% 0;
  }

  .weOfferHeadingDiv h3 {
    font-size: 32px;
  }

  .weOfferHeadingDiv p {
    width: 90%;
    font-size: 18px;
  }

  .weOfferCardImageDiv img {
    width: 100%;
  }

  .weOfferCardTextDiv h3 {
    font-size: 28px;
  }

  .weOfferCardBulletDiv p {
    font-size: 22px;
  }

  .animatedHexa {
    width: 110px;
    height: 110px;
  }

  .img1 {
    width: 100px;
  }

  .img2 {
    width: 90px;
  }
}

@media only screen and (max-width: 900px) {
  .weOfferHeadingDiv h3 {
    font-size: 30px;
  }

  .weOfferHeadingDiv p {
    width: 100%;
    font-size: 16px;
  }

  .weOfferCardBulletDiv img {
    width: 40px;
  }

  .weOfferCardTextDiv h3 {
    font-size: 24px;
  }

  .weOfferCardBulletDiv p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .weOfferCard {
    width: 80%;
    flex-direction: column;
    gap: 20px;
  }

  .weOfferCardImageDiv {
    width: 100%;
  }

  .weOfferCardImageDiv img {
    width: 75%;
  }

  .weOfferCardTextDiv {
    width: 90%;
  }

  .weOfferCardTextDiv h3 {
    font-size: 28px;
  }

  .weOfferCardBulletDiv p {
    font-size: 20px;
  }

  .animatedHexa {
    width: 90px;
    height: 90px;
  }

  .img1 {
    width: 85px;
  }

  .img2 {
    width: 75px;
  }
}

@media only screen and (max-width: 600px) {
  .weOfferHeadingDiv h3 {
    font-size: 28px;
  }

  .weOfferHeadingDiv p {
    font-size: 14px;
  }

  .weOfferCard {
    width: 90%;
  }

  .weOfferCardImageDiv img {
    width: 90%;
  }

  .weOfferCardTextDiv h3 {
    font-size: 24px;
  }

  .weOfferCardBulletDiv p {
    font-size: 18px;
  }

  .animatedHexa {
    width: 80px;
    height: 80px;
  }

  .img1 {
    width: 75px;
  }

  .img2 {
    width: 65px;
  }
}

@media only screen and (max-width: 480px) {
  .weOfferCard {
    width: 100%;
  }

  .weOfferCardImageDiv img {
    width: 100%;
  }

  .weOfferCardTextDiv h3 {
    font-size: 24px;
  }

  .weOfferCardBulletDiv p {
    font-size: 18px;
  }

  .animatedHexa {
    width: 55px;
    height: 55px;
  }

  .img1 {
    width: 50px;
  }

  .img2 {
    width: 45px;
  }
}
