.helpSectionContainer {
  width: 100%;
  padding: 5%;
  background: url(../../assets/images/helpBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.helpSectionContentDiv {
  position: relative;
  width: 100%;
  z-index: 2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.helpSectionTextDiv {
  width: 75%;
}

.helpSectionTextDiv h2 {
  color: #fff;
  font-family: Poppins;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 3%;
  border-left: 2px solid #004896;
}

.helpSectionTextDiv p {
  color: #fff;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 2%;
}

.helpSectionButtonDiv {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.helpSectionButtonDiv a {
  width: 80%;
  border-radius: 105px;
  background: #5b97af;
  border: none;
  padding: 2% 0;
  color: #fff;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .helpSectionTextDiv h2 {
    font-size: 48px;
  }

  .helpSectionTextDiv p {
    font-size: 22px;
  }

  .helpSectionButtonDiv a {
    width: 90%;
    padding: 3% 0;
    font-size: 22px;
  }
}

@media only screen and (max-width: 1024px) {
  .helpSectionTextDiv h2 {
    font-size: 42px;
  }

  .helpSectionTextDiv p {
    font-size: 20px;
  }

  .helpSectionButtonDiv a {
    width: 100%;
    padding: 3% 0;
    font-size: 22px;
  }
}

@media only screen and (max-width: 900px) {
  .helpSectionContentDiv {
    flex-direction: column;
    gap: 30px;
  }

  .helpSectionTextDiv {
    width: 100%;
  }

  .helpSectionButtonDiv {
    width: 100%;
    justify-content: flex-start;
  }

  .helpSectionButtonDiv a {
    width: 30%;
    padding: 1.5% 0;
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .helpSectionTextDiv h2 {
    font-size: 38px;
  }

  .helpSectionTextDiv p {
    font-size: 18px;
  }

  .helpSectionButtonDiv a {
    width: 40%;
  }
}

@media only screen and (max-width: 600px) {
  .helpSectionTextDiv h2 {
    font-size: 32px;
  }

  .helpSectionTextDiv p {
    font-size: 16px;
  }

  .helpSectionButtonDiv a {
    width: 50%;
  }
}

@media only screen and (max-width: 520px) {
  .helpSectionTextDiv h2 {
    font-size: 24px;
  }

  .helpSectionTextDiv p {
    font-size: 14px;
  }

  .helpSectionButtonDiv a {
    width: 100%;
    font-size: 18px;
  }
}
