.contactFormContainer {
  width: 100%;
  padding: 5%;
  display: flex;
  justify-content: space-between;
}

.contactFormTextDiv {
  width: 35%;
}

.contactFormTextDiv a {
  text-decoration: none;
}

.contactFormTextDiv h3 {
  color: #8c8fa3;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
}

.contactFormTextDiv h2 {
  color: #004896;
  font-family: Poppins;
  font-size: 66px;
  font-weight: 500;
  line-height: 1.1;
  margin: 2% 0;
}

.contactFormTextDiv h2 span {
  font-size: 106px;
}

.contactFormTextDiv p {
  color: #8c8fa3;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  margin: 1% 0;
}

.contactFormTextDiv h4 {
  color: #004896;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  margin-top: 2%;
}

.contactFormDiv {
  width: 60%;
}

.contactForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contactFormFieldDiv {
  width: 100%;
  border-radius: 18px;
  border: 1px solid #004896;
  /* overflow: hidden; */
}

.contactForm input {
  width: 100%;
  background: rgba(212, 221, 230, 0.2);
  padding: 2%;
  border: none;
  outline: none;
  border-radius: 18px;
}

.phoneInputField {
  width: 100%;
  background: rgba(212, 221, 230, 0.2);
  border: none !important;
  outline: none;
}

.react-tel-input .form-control {
  border: none !important;
}

.contactForm input::placeholder {
  color: #999;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
}

.contactForm textarea {
  width: 100%;
  border-radius: 18px;
  border: 1px solid #004896;
  background: rgba(212, 221, 230, 0.2);
  padding: 2%;
  height: 150px;
  resize: none;
}

.contactForm textarea::placeholder {
  color: #999;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
}

.contactForm button {
  width: 35%;
  border-radius: 105px;
  border: 1px solid #272d40;
  background: #5b97af;
  color: #fff;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 500;
  padding: 1% 0;
}

.contactFormTextDiv h6 {
  display: none;
}

@media only screen and (max-width: 1500px) {
  .contactFormTextDiv h3 {
    font-size: 22px;
  }

  .contactFormTextDiv h2 {
    font-size: 58px;
  }

  .contactFormTextDiv h2 span {
    font-size: 92px;
  }

  .contactFormTextDiv p {
    font-size: 22px;
  }

  .contactFormTextDiv h4 {
    font-size: 22px;
  }

  .contactForm button {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1200px) {
  .contactFormContainer {
    padding: 5%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  .contactFormTextDiv p {
    margin: 0;
    width: 80%;
  }

  .contactFormTextDiv {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contactFormTextDiv h6 {
    display: block;
    color: #004896;
    font-family: Poppins;
    font-size: 66px;
    font-weight: 500;
    line-height: 1.1;
    margin: 2% 0;
  }

  .contactFormTextDiv h2 {
    display: none;
  }

  .contactFormDiv {
    width: 70%;
  }
}

@media only screen and (max-width: 1024px) {
  .contactFormTextDiv h6 {
    font-size: 48px;
  }

  .contactFormTextDiv p {
    margin: 0;
    width: 80%;
    font-size: 18px;
  }

  .contactFormDiv {
    width: 80%;
  }

  .contactForm input {
    padding: 3%;
  }
}

@media only screen and (max-width: 900px) {
  .contactFormTextDiv h6 {
    font-size: 42px;
  }

  .contactFormTextDiv p {
    width: 90%;
    font-size: 16px;
  }

  .contactFormDiv {
    width: 90%;
  }

  .contactForm button {
    font-size: 22px;
    width: 45%;
    padding: 2% 0;
  }

  .contactForm input {
    padding: 4%;
  }
}

@media only screen and (max-width: 768px) {
  .contactFormDiv {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .contactForm button {
    width: 60%;
    padding: 3% 0;
  }

  .contactFormTextDiv h6 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 480px) {
  .contactFormTextDiv h6 {
    font-size: 32px;
  }

  .contactForm button {
    font-size: 20px;
  }
}
